export default [
    {
        prop: 'companyName',
        label: '公司名稱',
        width: '150', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'branchName',
        label: '分店名稱',
        width: '150', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'nickName',
        label: '暱稱',
        width: '150', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'brand',
        label: '資產品牌',
        width: '150', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'assetsModel',
        label: '資產類型',
        width: '150', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'assetsColor',
        label: '資產顏色',
        width: '150', //列寬
        showOverflowTooltip: false, //設置表格文字過長顯示省略號
        align: 'center',//對齊方式
        sortable: true
    },
    {
        prop: 'identifier',
        label: '資產識別碼',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'priceRange',
        label: '價格區間',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'isImportedString',
        label: '國內/國外',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'serviceLevel',
        label: '資產服務等級',
        width: '200',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'startDate',
        label: '會員資格起算日',
        width: '200',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'endDate',
        label: '會員資格終止日',
        width: '200',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'status',
        label: '會員資格狀態',
        width: '200',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'isUseString',
        label: '會員資格使用',
        width: '200',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'statusChangeDate',
        label: '會員資格狀態變更日',
        width: '250',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'purchaseAmount',
        label: '會員資格購買金額',
        width: '200',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'discountPercentage',
        label: '折扣率',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'isActiveString',
        label: '是否啟用',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'contactName',
        label: '聯絡人',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'contactGenderString',
        label: '聯絡人性別',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'contactPhoneNumber',
        label: '聯絡人電話',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
    {
        prop: 'remark',
        label: '備註',
        width: '150',
        showOverflowTooltip: false,
        align: 'center',
        sortable: true
    },
]
